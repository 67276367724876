<template>
  <div class="btn w-100 holder text-center mb-2 mb-md-0" @click.self="isHover = !isHover">
    <div class="row m-0 align-items-center justify-content-center gold-text h-100" @click="isHover = !isHover">
      <div  class="col-auto ps-0 pe-1">
        <div class="gold-text bold holder_font">{{placeholder}}</div>
      </div>
      <div class="col-auto pe-0 ps-1">
        <div class="spinner-border spinner-border-sm" role="status" v-if="busy">
          <span class="visually-hidden">Downloading...</span>
        </div>
        <IconCalendar color="gold" size="" v-else />
      </div>
    </div>
    <!--DESKTOP-->
    <transition name="slideBottom">
      <div class="select_dropdown" v-if="isHover" @click.self="isHover = !isHover">
        <div class="dropdown_inner shadow">
          <div class="row mx-0 my-4 justify-content-center d-md-none">
            <div class="col-11 text-center title">
              {{title}}
            </div>
            <div class="close cursor" @click="isHover = false"><ios-close-icon class="inline-icon"/></div>
          </div>
          <div class="row mx-0 mt-2 justify-content-center scroll_inner">
            <div class="col-12" >
              <VueDatePicker v-model="selectedDate" max-range="365" inline auto-apply model-type="yyyy-MM-dd" range :enable-time-picker="false" :month-change-on-scroll="false"></VueDatePicker>
            </div>
          </div>
          <div class="row mx-0 mb-3 justify-content-center">
            <div class="col-auto">
              <Button color="green" width="100" btnText="Apply Date Range" icon="arrow" @buttonClicked="downloadRange" :disabled="!dateRange.startDate || !dateRange.endDate">
                <ios-checkmark-icon class="inline-icon" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!--END DESKTOP-->
  </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import '@/assets/css/datepicker.css'
import { DateTime } from 'luxon'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('../Button.vue')),
    IconCalendar: defineAsyncComponent(() => import('../icons/IconCalendar.vue')),
    VueDatePicker,
    'ios-checkmark-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-checkmark.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue'))
  },
  name: 'Date Range Select',
  props: ['placeholder', 'title', 'busy'],
  emits: ['update:modelValue', 'applyfilter', 'downloadRange'],
  data () {
    return {
      isHover: false,
      dateRange: {
        startDate: null,
        endDate: null
      },
      selectedDate: {
        start: 'First Date',
        end: 'Last Date'
      }
    }
  },
  watch: {
    selectedDate: {
      deep: true,
      handler (val) {
        this.setDateRange(this.selectedDate)
      }
    }
  },
  methods: {
    clearDateRange () {
      this.dateRange.startDate = null
      this.dateRange.endDate = null
    },
    setDateRange () {
      if (this.selectedDate) {
        if (this.selectedDate.start !== 'First Date') {
          this.dateRange.startDate = DateTime.fromISO(this.selectedDate[0]).startOf('day').ts
        }
        if (this.selectedDate.end !== 'Last Date') {
          this.dateRange.endDate = DateTime.fromISO(this.selectedDate[1]).endOf('day').ts
        }
      } else {
        console.log('this.selectedDate', this.selectedDate.toString())
      }
    },
    downloadRange () {
      this.$emit('downloadRange', this.dateRange)
      this.dateRange = {
        startDate: null,
        endDate: null
      }
      this.selectedDate = {
        start: 'First Date',
        end: 'Last Date'
      }
      this.isHover = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .btn {
    border-radius: 2rem;
    font-size: 15px;
    padding: 0 24px;
    line-height: 15px;
    border: 2px solid transparent;
    height: 39px;
  }
  .holder {
    background-color: var(--green-color-dark);
    border: 2px solid var(--green-color-dark);
    color: var(--gold-color);
    position: relative;
    cursor: pointer;
  }
  .holder:hover {
    background-color: var(--green-color-light);
    color: var(--gold-color);
  }
  .holder_font {
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  .dropdown_icon {
    position: relative;
    color: var(--gold-color);
    top: 13px;
    right: 16px;
    font-size: 1rem;
    z-index: 1;
    display: inline-block;
  }
  .select_dropdown {
    position: fixed;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    width: 100%;
    min-height: 60px;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9;
    /* background-color: pink; */
  }
  .title {
    color: var(--green-color-dark);
    font-family: "quicksand_medium";
    font-weight: 600;
    font-size: 19px;
  }
  .close {
    position: absolute;
    right: 0;
    top: 0.5rem;
    width: inherit;
  }
  .dropdown_inner {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 75vh;
    background-color: rgb(249, 250, 251);
    border-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    color: rgb(51, 51, 51);
  }
  .scroll_inner {
    max-height: 350px;
    overflow-y: auto;
  }
  .form-control {
    background-color: #fff;
    color: var(--green-color);
    border-color: var(--green-color);
    border-radius: 2rem;
    font-size: 1rem;
    height: 2.5rem;
    font-weight: 500;
    text-align: left;
  }
  .slideBottom-enter-active {
    transition: all 0.5s ease;
  }
  .slideBottom-leave-active {
    transition: all 0.5s ease;
  }
  .slideBottom-enter-from {
    transform: translateY(100%);
    opacity: 1;
  }
  .slideBottom-leave-to {
    transform: translateY(100%);
    opacity: 1;
  }
  @media (min-width: 992px) {
    .select_dropdown {
      position: absolute;
      left: 0;
      bottom: inherit;
      top: 0;
      width: 100%;
      min-height: 60px;
      height: inherit;
      background-color: transparent;
      /* background-color: pink; */
    }
    .dropdown_inner {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: inherit;
      max-height: inherit;
      top: 50px;
      /* width: 150%; */
      min-width: calc(250px + 2rem);
      background-color: rgb(249, 250, 251);
      border-radius: 20px;
      color: rgb(51, 51, 51);
    }

    .holder_font {
      font-size: 16px;
    }
    .slideBottom-enter-active {
      transition: all 0.5s ease;
    }
    .slideBottom-leave-active {
      transition: all 0.1s ease;
    }
    .slideBottom-enter-from {
      transform: translateY(0);
      opacity: 0;
    }
    .slideBottom-leave-to {
      transform: translateY(0);
      opacity: 1;
    }
  }
</style>
